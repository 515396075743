module.exports = {
  url: "baseurl", // modify this setting only if explicit pointing is required.
  baseurl: process.env.REACT_APP_GAID
    ? "https://app.garykirstencricketindia.in"
    : "https://app.garykirstencricketindia.in",
  sandbox: "https://app.garykirstencricketindia.in",
  localurl: "http://localhost:3333",
  ngrokurl: "https://80db-2401-4900-1c1b-b56-c0d5-d883-4c08-496a.ngrok-free.app",
  apiKey: "9542fb433949640e3eeb1b68907d74231cbd756f",
  contentType: "application/json",
  endpoints: {
    vehicles: "/api/v1/vehicles/",
    jobmasters: "/api/v1/jobmasters/",
    categories: "/api/v1/admin/categories/",
    customers: "/customer",
    customersID: "/customer/:id",
    admins: "/customers/list",
    users: "/api/v1/admin/",
    orders: "/api/v1/admin/orders/",
    jobcards: "/api/v1/jobcards/",
    jobCardItem: "/api/v1/jobcard-item/",
    login: "/admin/login",
    stats: "/api/v1/stats",
    payments: "/api/v1/payments/",
    packages: "/api/v1/packages/",
    packageItems: "/api/v1/package-items/",
    refunds: "/api/v1/refunds/",
    partners: "/api/v1/partners/",
    locations: "/api/v1/locations/",
    stores: "/api/partner/",
    stocks: "/api/v1/stocks/",
    sizes: "/api/v1/sizes/",
    images: "/api/v1/inventory_images/",
    configurations: "/api/v1/configurations/",
    shipments: "/api/v1/shipments/",
    invoices: "/api/v1/invoices/",
    search: "/api/v1/search/",
  },
};
