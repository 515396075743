import { authHeader } from "./authHeader";
import { authHeaderStore } from "./authHeaderStore";
import config from "./../data/config";
import base64 from "base-64";
import queryString from "query-string";
// import axios from 'axios';

export const APIService = {
  login,
  logout,
  fetchAllCustomers,
  createCustomer,
  updateCustomer,
  fetchCustomerDetail,
  fetchAllCategories,
  fetchCategoryDetail,
  fetchAllVehicles,
  searchVehicle,
  createVehicle,
  fetchVehicleDetail,
  updateVehicle,
  fetchAllJobmaster,
  deleteJobmaster,
  addJobMaster,
  updateJobmaster,
  updateJobCardItem,
  addJobcardNote,
  fetchAllPackages,
  createPackage,
  fetchPackageDetail,
  updatePackage,
  addJobToPackage,
  removeJobFromPackage,
  searchText,
  fetchAllUsers,
  createUser,
  updateUser,
  deactivateUser,
  reactivateUser,
  createPayment,
  deletePayment,
  deleteInventory,
  fetchAllAdmins,
  addAdmin,
  fetchAllOrders,
  fetchOrderDetails,
  createJobCard,
  updateJobCard,
  rollbackStatus,
  fetchJobcardDetails,
  deleteJobCardItem,
  addJobToJobcard,
  sendMessage,
  fetchAllPayments,
  getSignedURL,
  uploadFileToS3,
  dashboardStats,
  fetchAllPartners,
  fetchPartnerDetails,
  deletePartner,
  fetchAllLocations,
  addLocation,
  partnerLogin,
  partnerLogout,
  fetchAllSizes,
  addSize,
  deleteSize,
  fetchConfigurations,
  updateConfigurations,
  fetchLocationStocks,
  fetchPartnerStocks,
  addPartnerStock,
  fetchAllRiders,
  addRider,
  fetchAllRefunds,
  fetchRefundStatus,
  fetchAllImages,
  addImages,
  createShipments,
  updatePartner,
  updateShipment,
  sendInvoice,
  updateShipmentStatus,
  fetchJobmasterDetail,
  updateImages,
  fetchUserDetails,
};

const baseUrl =
  config.url === "baseurl"
    ? config.baseurl
    : config.url === "localurl"
    ? config.localurl
    : config.url === "ngrokurl"
    ? config.ngrokurl
    : config.url === "sandbox"
    ? config.sandbox
    : window.location.hostname;

// const instance = axios.create({ baseURL: baseUrl });

const headers = new Headers();
headers.append('Content-Type', 'application/json');

async function login(username, password) {
  console.log("login func", authHeader());
  const requestOptions = {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
    },
    
    body: JSON.stringify({ username: username, password }),
  };
  
  return fetch(baseUrl + config.endpoints.login, requestOptions)
    .then(_handleResponse)
    .then(async (user) => {
      console.log("user", user)
      if (user) {
        user.auth_data = user.token;
        await localStorage.setItem("user", JSON.stringify(user));
        // await fetchAllJobmaster();
      }
      return user;
    });
}

function logout() {
  localStorage.removeItem("user");
  localStorage.removeItem("units");
  localStorage.removeItem("categories");
  localStorage.removeItem("inventories");
  localStorage.removeItem("orderStatuses");
  localStorage.removeItem("city_id");
  localStorage.clear();
}

async function fetchAllCustomers() {
  const requestOptions = {
    method: "GET",
    headers: await authHeader(),
  };

  return fetch(baseUrl + config.endpoints.customers, requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function createCustomer(body) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.customers + "create", requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function updateCustomer(id, body) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.customers + id, requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

async function fetchCustomerDetail(id) {
  const requestOptions = {
    method: "GET",
    headers: await authHeader(),
  };

  return fetch(baseUrl + config.endpoints.customersID + id, requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

async function fetchCategoryDetail(id) {
  const requestOptions = {
    method: "GET",
    headers: await authHeader(),
  };

  return fetch(baseUrl + config.endpoints.categories + id, requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

async function fetchAllCategories() {
  const requestOptions = {
    method: "GET",
    headers: await authHeader(),
  };

  return fetch(baseUrl + config.endpoints.categories + 'list', requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function fetchAllVehicles() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.vehicles, requestOptions)
    .then(_handleResponse)
    .then(async (data) => {
      return data;
    });
}

function searchVehicle(body) {
  let qs = queryString.stringify(body);
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    baseUrl + config.endpoints.vehicles + "search?" + qs,
    requestOptions
  )
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function createVehicle(body) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.vehicles + "create", requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function fetchVehicleDetail(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.vehicles + id, requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function updateVehicle(id, body) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.vehicles + id, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    });
}

function updateShipmentStatus(id, body) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(
    baseUrl + config.endpoints.shipments + id + "/deliver",
    requestOptions
  )
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function deleteJobmaster(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.jobmasters + id, requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function fetchAllJobmaster() {
  fetchAllVehicles();
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.jobmasters, requestOptions)
    .then(_handleResponse)
    .then(async (data) => {
      await localStorage.setItem("jobMasters", JSON.stringify(data));
      return data;
    });
}

function addJobMaster(body) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.jobmasters + "create", requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function updateJobmaster(id, body) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.jobmasters + id, requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function fetchAllPackages() {
  fetchAllVehicles();
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.packages, requestOptions)
    .then(_handleResponse)
    .then(async (data) => {
      await localStorage.setItem("packages", JSON.stringify(data));
      return data;
    });
}

function deleteJobCardItem(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.jobCardItem + id, requestOptions)
    .then(_handleResponse)
    .then((success) => {
      return success;
    });
}

function createPackage(body) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.packages + "create", requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function fetchPackageDetail(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.packages + id, requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function updatePackage(id, body) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.packages + id, requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function addJobToPackage(id, body) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(
    baseUrl + config.endpoints.packages + id + "/item",
    requestOptions
  )
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function removeJobFromPackage(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.packageItems + id, requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function searchText(str) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.search + str, requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function updateShipment(id, body) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.shipments + id, requestOptions)
    .then(_handleResponse)
    .then((shipment) => {
      return shipment;
    });
}

function updatePartner(id, body) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.partners + id, requestOptions)
    .then(_handleResponse)
    .then((partner) => {
      return partner;
    });
}

function deleteInventory(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.inventories + id, requestOptions)
    .then(_handleResponse)
    .then((inventory) => {
      return inventory;
    });
}

function fetchAllAdmins() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.admins, requestOptions)
    .then(_handleResponse)
    .then((admins) => {
      return admins;
    });
}

function addAdmin(body) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.admins, requestOptions)
    .then(_handleResponse)
    .then((admin) => {
      return admin;
    });
}

async function fetchAllOrders() {
  const requestOptions = {
    method: "GET",
    headers: await authHeader(),
  };

  return fetch(baseUrl + config.endpoints.orders + 'list', requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

async function fetchOrderDetails(id) {
  const requestOptions = {
    method: "GET",
    headers: await authHeader(),
  };

  return fetch(baseUrl + config.endpoints.orders + id, requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function createJobCard(body) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.jobcards + "create", requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function updateJobCard(id, body) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.jobcards + id, requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function rollbackStatus(id) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
  };

  return fetch(
    baseUrl + config.endpoints.jobcards + id + "/rollback",
    requestOptions
  )
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function updateJobCardItem(id, body) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.jobCardItem + id, requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function addJobcardNote(id, body) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(
    baseUrl + config.endpoints.jobcards + id + "/notes",
    requestOptions
  )
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function fetchJobcardDetails(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.jobcards + id, requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function addJobToJobcard(id, body) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(
    `${baseUrl}${config.endpoints.jobcards}${id}/jobs`,
    requestOptions
  )
    .then(_handleResponse)
    .then((data) => {
      fetchAllJobmaster();
      return data;
    });
}

async function fetchUserDetails(id) {
  const requestOptions = {
    method: "GET",
    headers: await authHeader(),
  };

  return fetch(baseUrl + config.endpoints.customers + '/' + id, requestOptions)
    .then(_handleResponse)
    .then((user) => {
      return user;
    });
}

function fetchJobmasterDetail(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.jobmasters + id, requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

async function fetchAllUsers() {
  const requestOptions = {
    method: "GET",
    headers: await authHeader(),
  };

  return fetch(baseUrl + config.endpoints.customers + 's/list' , requestOptions)
    .then(_handleResponse)
    .then((users) => {
      return users;
    });
}

async function createUser(body) {
  const requestOptions = {
    method: "POST",
    headers: await authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.users + "create", requestOptions)
    .then(_handleResponse)
    .then((users) => {
      return users;
    });
}

async function updateUser(id, body) {
  const requestOptions = {
    method: "POST",
    headers: await authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.users + `admin/${id}`, requestOptions)
    .then(_handleResponse)
    .then((user) => {
      return user;
    });
}

async function deactivateUser(id) {
  const requestOptions = {
    method: "DELETE",
    headers: await authHeader(),
  };

  return fetch(
    baseUrl + config.endpoints.users + `delete/${id}`,
    requestOptions
  )
    .then(_handleResponse)
    .then((user) => {
      return user;
    });
}

function reactivateUser(id) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
  };

  return fetch(
    baseUrl + config.endpoints.users + id + "/reactivate",
    requestOptions
  )
    .then(_handleResponse)
    .then((user) => {
      return user;
    });
}

function createPayment(body) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.payments + "create", requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function deletePayment(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.payments + id, requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function sendMessage(body) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(
    baseUrl + config.endpoints.users + "notification",
    requestOptions
  )
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function sendInvoice(body) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.invoices + "send", requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function dashboardStats() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.stats, requestOptions)
    .then(_handleResponse)
    .then((stats) => {
      fetchAllVehicles();
      return stats;
    });
}

function fetchAllPayments() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.payments, requestOptions)
    .then(_handleResponse)
    .then((payments) => {
      return payments;
    });
}

function getSignedURL(body) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(
    baseUrl + config.endpoints.inventories + "signed_url",
    requestOptions
  )
    .then(_handleResponse)
    .then((url) => {
      return url;
    });
}

function uploadFileToS3(signedURL, body, headers) {
  const requestOptions = {
    method: "PUT",
    headers: headers,
    body: JSON.stringify(body),
  };

  return fetch(signedURL, requestOptions)
    .then(_handleResponse)
    .then(
      (success) => {
        return success;
      },
      (error) => {
        return error;
      }
    );
}

function fetchAllPartners() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.partners, requestOptions)
    .then(_handleResponse)
    .then(async (partners) => {
      await localStorage.setItem("partners", JSON.stringify(partners));
      return partners;
    });
}

function fetchPartnerDetails(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.partners + id, requestOptions)
    .then(_handleResponse)
    .then((partner) => {
      return partner;
    });
}

function createShipments(body) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.shipments, requestOptions)
    .then(_handleResponse)
    .then((shipmentData) => {
      return shipmentData;
    });
}

function deletePartner(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.partners + id, requestOptions)
    .then(_handleResponse)
    .then((success) => {
      return success;
    });
}

function fetchAllLocations() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.locations, requestOptions)
    .then(_handleResponse)
    .then((locations) => {
      return locations;
    });
}

function addLocation(body) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.locations, requestOptions)
    .then(_handleResponse)
    .then((location) => {
      return location;
    });
}

function partnerLogin(user, password) {
  const basicAuthString = base64.encode(user + ":" + password);
  const requestOptions = {
    method: "GET",
    headers: authHeaderStore(user, password),
  };

  return fetch(baseUrl + config.endpoints.stores + "login", requestOptions)
    .then(_handleResponse)
    .then(async (partner) => {
      if (partner) {
        partner.auth_data = basicAuthString;
        await localStorage.setItem("partner", JSON.stringify(partner));
      }
      return partner;
    });
}

function partnerLogout() {
  localStorage.removeItem("partner");
}

function fetchAllSizes() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.sizes, requestOptions)
    .then(_handleResponse)
    .then((sizes) => {
      return sizes;
    });
}

function addSize(body) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.sizes, requestOptions)
    .then(_handleResponse)
    .then((size) => {
      return size;
    });
}

function deleteSize(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.sizes + id, requestOptions)
    .then(_handleResponse)
    .then((success) => {
      return success;
    });
}

function fetchConfigurations() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.configurations, requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function updateConfigurations(body) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.configurations, requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function fetchLocationStocks(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.stock + id, requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function fetchPartnerStocks(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.stocks + id, requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}
function addPartnerStock(body) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.stocks, requestOptions)
    .then(_handleResponse)
    .then((stock) => {
      return stock;
    });
}
// function fetchAllStocks() {
// 	const requestOptions = {
// 		method: 'GET',
// 		headers: authHeader()
// 	};

// 	return fetch(baseUrl + config.endpoints.stock, requestOptions)
// 		.then(_handleResponse)
// 		.then(async (stock) => {
// 			await localStorage.setItem('stock', JSON.stringify(stock));
// 			return stock;
// 		});
// }
/*function fetchAllCities() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(baseUrl + config.endpoints.cities, requestOptions)
    .then(_handleResponse)
    .then(async (data) => {
      await localStorage.setItem('cities', JSON.stringify(data));
      return data;
    });
}

function addCity(body) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(body)
  };

  return fetch(baseUrl + config.endpoints.cities, requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function deleteCity(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return fetch(baseUrl + config.endpoints.cities + id, requestOptions)
    .then(_handleResponse)
    .then((success) => {
      return success;
    });
}*/

function fetchAllRiders() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.riders, requestOptions)
    .then(_handleResponse)
    .then(async (data) => {
      return data;
    });
}

function addImages(body) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.images, requestOptions)
    .then(_handleResponse)
    .then((Image) => {
      return Image;
    });
}

function updateImages(id, body) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.images + id, requestOptions)
    .then(_handleResponse)
    .then((category) => {
      return category;
    });
}

function fetchAllImages(inventory_id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.images + inventory_id, requestOptions)
    .then(_handleResponse)
    .then((images) => {
      localStorage.setItem("images", JSON.stringify(images));
      return images;
    });
}

function addRider(body) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(body),
  };

  return fetch(baseUrl + config.endpoints.riders, requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function fetchAllRefunds() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.refunds, requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function fetchRefundStatus(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(baseUrl + config.endpoints.refunds + id, requestOptions)
    .then(_handleResponse)
    .then((data) => {
      return data;
    });
}

function _handleResponse(response) {
  return response.text().then((text) => {
    console.log("_handleResponse text", text);
    let data = text && JSON.parse(text);
    data.statusText = response.statusText;
    if (!response.ok) {
      if (response.status === 401) {
        logout();
        if (new URL(response.url).pathname !== "/api/dashboard/admins/login") {
          window.location.reload(true);
        }
      }
      return Promise.reject(data);
    }

    return data;
  });
}
