import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  ButtonGroup,
  Alert,
} from "shards-react";
import { utils, writeFileXLSX } from "xlsx";
import * as XLSX from "xlsx";
import CsvDownloadButton from "react-json-to-csv";
import PageTitle from "../../components/common/PageTitle";
import APIError from "../../components/common/APIError";
import Loader from "../../components/Loader/Loader";
import { Redirect } from "react-router-dom";
import { APIService } from "./../../utils/APIService";
import userLoginStatus from "./../../utils/userLoginStatus";
import ReactTimeAgo from "react-time-ago";
import { appName } from "../../data/constants";
import { isArray } from "lodash";
import moment from "moment";

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this._dismissAlert = this._dismissAlert.bind(this);
    this.state = {
      loginStatus: undefined,
      loading: false,
      errorMessage: false,
      filterBy: "All",
      visible: false,
      listData: [],
      visible: this.props.location.state
        ? this.props.location.state.visible
        : false,
      alertStyle: this.props.location.state
        ? this.props.location.state.alertStyle
        : "",
      alertIcon: this.props.location.state
        ? this.props.location.state.alertIcon
        : "",
      alertMessage: this.props.location.state
        ? this.props.location.state.alertMessage
        : "",
    };
    this.deactivateUser = this.deactivateUser.bind(this);
    this._dismissAlert = this._dismissAlert.bind(this);
  }

  componentDidMount() {
    let { loginStatus } = this.state;
    if (loginStatus === undefined) {
      userLoginStatus().then(
        (value) => {
          this._fetchListData();
        },
        (reason) => {
          this.setState({ loginStatus: false });
        }
      );
    }
  }

  _dismissAlert() {
    this.setState({ visible: false });
  }

  handleDownloadExcel = (dataSource, sheetName, fileName) => {
    
    const ws = utils.json_to_sheet(dataSource);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, sheetName);
    writeFileXLSX(wb, `${fileName}.xlsx`);
  };

  //  ExportToExcel = ({ data, sheetName, fileName }) => {
   handleExportToExcel = async ( sheetName, fileName) => {
      const data = await this.getFormatData(this.state.listData)
      console.log("Data", data)
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
      writeFileXLSX(workbook, `${fileName}.xlsx`);

      // Create a Blob object representing the Excel file
      // const blob = XLSX.write(workbook, { bookType: "xlsx", type: "blob" });

      // // Create a URL for the Blob
      // const url = URL.createObjectURL(blob);

      // Create a hidden anchor element and trigger a click to download the file
      // const a = document.createElement("a");
      // a.href = url;
      // a.download = fileName;
      // a.click();

      // // Revoke the URL to release resources
      // URL.revokeObjectURL(url);
    };

  //   return (
  //     <button onClick={handleExportToExcel}>Export to Excel</button>
  //   )
  // }

  _fetchListData = () => {
    APIService.fetchAllUsers().then(
      (data) => {
        console.log("fetchAllUsersdata", data)
        this.setState({
          loginStatus: true,
          loading: false,
          listData: data,
        });
      },
      (error) => {
        this.setState({
          loading: false,
          errorMessage:
            error.errorMessage ||
            "Cannot connect to server. Either there's a problem with your internet connection or the server is down.",
          errorCode: error.errorStatus || "NOT CONNECTED",
        });
      }
    );
  };

  deactivateUser(e, id) {
    e.preventDefault();
    if (window.confirm("Are you sure you want to deactivate this user?")) {
      this.setState({ loading: true });
      APIService.deactivateUser(id).then(
        (success) => {
          this._fetchListData();
        },
        (error) => {
          if (!error.errorStatus || error.errorStatus === 500) {
            this.setState({
              loading: false,
              errorMessage:
                error.errorMessage ||
                "Cannot connect to server. Either there's a problem with your internet connection or the server is down.",
              errorCode: error.errorStatus || "NOT CONNECTED",
            });
          } else {
            this.setState({
              loading: false,
              visible: true,
              alertStyle: "danger",
              alertIcon: "fa fa-times-circle mx-2",
              alertMessage: error.errorMessage,
            });
          }
        }
      );
    }
  }

  reactivateUser(e, id) {
    e.preventDefault();
    if (window.confirm("Are you sure you want to reactivate this user?")) {
      this.setState({ loading: true });
      APIService.reactivateUser(id).then(
        (success) => {
          this._fetchListData();
        },
        (error) => {
          if (!error.errorStatus || error.errorStatus === 500) {
            this.setState({
              loading: false,
              errorMessage:
                error.errorMessage ||
                "Cannot connect to server. Either there's a problem with your internet connection or the server is down.",
              errorCode: error.errorStatus || "NOT CONNECTED",
            });
          } else {
            this.setState({
              loading: false,
              visible: true,
              alertStyle: "danger",
              alertIcon: "fa fa-times-circle mx-2",
              alertMessage: error.errorMessage,
            });
          }
        }
      );
    }
  }

  renderRow = (Obj, i) => {
    // var createdAt = new Date(Obj?.created_at);
    // createdAt.setHours(createdAt.getHours() + 5);
    // createdAt.setMinutes(createdAt.getMinutes() + 30);
    console.log("renderRow", Obj);
    return (
      <tr key={i}>
        <td>{Obj?.id}</td>
        {/* <td onClick={() => this.setState({redirect:true, redirectPath: "/users/" + Obj.id + "/detail"})}>{Obj.id}</td> */}

        {/* {Obj.name ? Obj.name : "NA"} */}
        <td>{Obj?.mobile}</td>
        {/* <td>{Obj?.username}</td> */}
        <td>
          {/* <ReactTimeAgo date={createdAt} locale="en-US" /> */}
          {moment(Obj.created_at).format("DD-MM-YYYY hh:mm A")}
          {/* {moment(Obj.created_at, 'ddd DD-MMM-YYYY, hh:mm A').format('hh:mm A')} */}
        </td>
        <td align="right">
          <ButtonGroup size="sm">
            <Button
              outline
              theme="primary"
              className="mb-2 mr-1"
              onClick={() =>
                this.setState({
                  redirect: true,
                  redirectPath: `/users/${Obj.id}/Detail`,
                })
              }
            >
              Details
            </Button>
          </ButtonGroup>
        </td>
      </tr>
    );
  };

  getFormatData = async (data) => {
    const formatedData = await data?.map((item) => {
      if (item?.enquiries && item?.enquiries?.length) {
        return item?.enquiries?.map((item1) => {
          if (item1?.demo_sessions && item1?.demo_sessions?.length) {
            return item1?.demo_sessions?.map((item2) => {
              return { ...item1, ...item2, ...item };
            });
          } else {
            return { ...item1, item };
          }
        });
      } else {
        return item;
      }
    });

    let HOLAFF = []
    const hola123 = await formatedData?.map((item) => {
      return item?.map((item2) => {
        console.log("item2", isArray(item2));
        if (isArray(item2)){
          HOLAFF.push(item2[0]);
          return item2;
        }else{
          HOLAFF.push(item2);
          return item2;
        } 
      })
    })

    return HOLAFF;

  }

  render() {
    const tableBody =
      this.state.listData.length && this.state.listData.map(this.renderRow);
    const {
      loginStatus,
      loading,
      errorMessage,
      errorCode,
      alertMessage,
      visible,
      redirectPath,
      redirectData,
      alertStyle,
      alertIcon,
    } = this.state;

    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: redirectPath,
            state: redirectData,
          }}
        />
      );
    }
    if (errorMessage && errorCode) {
      return <APIError code={errorCode}>{errorMessage}</APIError>;
    }
    if (loading || loginStatus === undefined) {
      return <Loader />;
    } else if (loginStatus) {
      return (
        <>
          <Alert
            theme={alertStyle || "primary"}
            dismissible={this._dismissAlert}
            open={visible}
          >
            <i className={alertIcon} /> {alertMessage}
          </Alert>
          <Container fluid className="main-content-container px-4">
            <Row>
              <Col>
                <Row noGutters className="page-header py-4">
                  <Col>
                    <PageTitle
                      sm="4"
                      title="Admins"
                      subtitle={appName}
                      className="text-sm-left"
                    />
                  </Col>
                </Row>
              </Col>
              <Row className="text-md-right" sm="4" xs="12">
                <Row noGutters className="page-header py-4">
                  <Col />
                </Row>
              </Row>
            </Row>
            <Row>
              <Col>
                <Card small className="mb-4">
                  <CardHeader className="border-bottom">
                    <Row>
                      <Col>
                        <h6 className="m-0">
                          Users ({this.state.listData?.length})
                        </h6>
                      </Col>
                      <Col className="text-md-right">
                        {/* <CsvDownloadButton data={this.state.listData} /> */}
                        <Button
                          outline
                          theme="primary"
                          className="mb-2 mr-1"
                          onClick={async () =>
                            this.handleExportToExcel(
                              
                              "SHEET_NAME",
                              "MY_FILENAME"
                            )
                          }
                        >
                          Download Excel
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="p-0 pb-3">
                    <table className="table mb-0">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col" className="border-0">
                            id
                          </th>

                          <th scope="col" className="border-0">
                            Phone
                          </th>
                          {/* <th scope="col" className="border-0">
                            Username
                          </th> */}
                          <th scope="col" className="border-0">
                            Created
                          </th>
                          {/* <th scope="col" className="border-0">
                            Action
                          </th> */}
                          <th scope="col" className="border-0" align="right" />
                        </tr>
                      </thead>
                      <tbody>{tableBody}</tbody>
                    </table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      );
    } else {
      return <Redirect to="/login" />;
    }
  }
}

export default UserList;
