import React from "react";
import {
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Badge,
} from "shards-react";
import { Link, Redirect } from "react-router-dom";
import { APIService } from "../../../utils/APIService";

export default class NavbarSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputText: "",
      searchResult: undefined,
    };
    this.delayTimer = null;
    this._handleChange = this._handleChange.bind(this);
  }

  componentWillUnmount() {
    clearTimeout(this.delayTimer);
  }

  makeAPICall = async () => {
    const { inputText } = this.state;
    if (inputText.length < 3) {
      return;
    }
    try {
      APIService.searchText(inputText).then(
        (result) => {
          this.setState({ searchResult: result });
        },
        (error) => {
          alert(error?.message || error?.errorMessage);
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  _handleChange(event) {
    const { value } = event.target;
    this.setState({ inputText: value }, () => {
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        if (value !== "") {
          this.makeAPICall();
        }
      }, 500);
    });
  }

  renderDropdown() {
    const { searchResult } = this.state;
    if (!searchResult) {
      return <></>;
    } else if (searchResult.data.length) {
      return (
        <Dropdown open={true}>
          <DropdownMenu>
            {searchResult.data.map((obj) => {
              return (
                <DropdownItem
                  style={{ width: 300 }}
                  onClick={() => {
                    this.setState({
                      redirect: true,
                      redirectPath: `/${
                        obj.registration_number
                          ? "vehicles"
                          : obj.phone
                          ? "customers"
                          : "jobcards"
                      }/${obj.id}/detail`,
                    });
                  }}
                >
                  <Row form className="d-md-flex flex-md-row">
                    <Col>
                      {obj.name || obj.phone || obj.registration_number}
                    </Col>
                    <Col className="text-md-right">
                      <Badge>
                        {obj.registration_number
                          ? "Vehicle"
                          : obj.phone
                          ? "Customer"
                          : "Job Card"}
                      </Badge>
                    </Col>
                  </Row>
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
      );
    } else {
      return (
        <Dropdown open={true}>
          <DropdownMenu>
            <DropdownItem style={{ width: 300 }}>
              <Row form className="d-md-flex flex-md-row">
                <Col>No results found</Col>
                <Col className="text-md-right">
                  <Link
                    to="#"
                    onClick={() => {
                      this.setState({ searchResult: undefined });
                    }}
                  >
                    <i class="material-icons">cancel</i>
                  </Link>
                </Col>
              </Row>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      );
    }
  }

  render() {
    const { inputText, redirectPath, redirectData } = this.state;

    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: redirectPath,
            state: redirectData,
          }}
        />
      );
    }

    return (
      <Form className="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
        <InputGroup seamless className="ml-3">
          {this.renderDropdown()}
          {/* <InputGroupAddon type="prepend">
            <InputGroupText>
              <i className="material-icons">search</i>
            </InputGroupText>
          </InputGroupAddon> */}
          {/* <FormInput
            className="navbar-search"
            placeholder="Search for vehicle, customer or jobcard..."
            value={inputText}
            onChange={this._handleChange}
          /> */}
        </InputGroup>
      </Form>
    );
  }
}
