import React from "react";
import PropTypes from "prop-types";
import { Container } from "shards-react";

const APIError = ({ children, code }) => {
  return (
    <Container fluid className="main-content-container">
      <div className="error">
        <div className="error__content">
          <h2>{code || null}</h2>
          <h3>{children || "Unknown Error"}</h3>
        </div>
      </div>
    </Container>
  );
};

APIError.propTypes = {
  /**
   * The page title.
   */
  message: PropTypes.string,
};

export default APIError;
