import React from "react";
import { useHistory } from "react-router-dom";
import { Nav, Button, Container } from "shards-react";

// import Notifications from "./Notifications";
import UserActions from "./UserActions";
import userLoginStatus from "../../../../utils/userLoginStatus";

async function getLoggedInUser() {
  return userLoginStatus();
}

export default () => {
  const history = useHistory();
  const navigateTo = () => history.push("/jobcards/new");
  let loggedInUser = getLoggedInUser();
  return (
    <>
      <Nav navbar className="border-left flex-row">
        {/* <Button onClick={navigateTo}>New Jobcard</Button> */}
        <UserActions />
      </Nav>
    </>
  );
};
