import React from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import { Store } from "../../../flux";
import userLoginStatus from "../../../utils/userLoginStatus";
import { displayMenuItem } from "../../../utils/permissions";

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navItems: Store.getSidebarItems(),
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    Store.addChangeListener(this.onChange);
    userLoginStatus().then((data) => {
      this.setState({ userData: data });
    });
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      navItems: Store.getSidebarItems(),
    });
  }

  render() {
    const { navItems: items, userData } = this.state;
    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {items.map((item, idx) => {
            let q = displayMenuItem(item.title, userData?.role);
            return q ? <SidebarNavItem key={idx} item={item} /> : <></>;
          })}
        </Nav>
      </div>
    );
  }
}

export default SidebarNavItems;
