export default function () {
  return [  
    {
      title: "Users",
      htmlBefore: '<i class="material-icons">manage_accounts</i>',
      to: "/users",
    },
    
  ];
}
