import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  ButtonGroup,
  FormGroup,
  FormInput,
  FormTextarea,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import APIError from "../../components/common/APIError";
import Loader from "../../components/Loader/Loader";
import { Redirect } from "react-router-dom";
import { APIService } from "./../../utils/APIService";
import userLoginStatus from "./../../utils/userLoginStatus";
import { appName } from "../../data/constants";
import moment from "moment";

class UserDetail extends React.Component {
  constructor(props) {
    super(props);
    this._dismissAlert = this._dismissAlert.bind(this);
    this.state = {
      loginStatus: undefined,
      loading: false,
      listItems: false,
      internetConnected: true,
      open: false,
      modalData: {},
      visible: false,
      id: this.props.match.params.id,
      data: {},
    };

    /* alert(JSON.stringify(this.props.location.state, null, 2)); */

    this._handleChange = this._handleChange.bind(this);
    this._handleSendMessage = this._handleSendMessage.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    if (this.state.loginStatus === undefined) {
      userLoginStatus().then(
        (value) => {
          this._fetchDetail();
        },
        (reason) => {
          this.setState({ loginStatus: false });
        }
      );
    }
  }

  _fetchDetail = () => {
    let { id } = this.state;
    APIService.fetchUserDetails(id).then(
      (data) => {
        this.setState({
          loginStatus: true,
          loading: false,
          data,
        });
      },
      (error) => {
        this.setState({
          loading: false,
          errorMessage:
            error.errorMessage ||
            "Cannot connect to server. Either there's a problem with your internet connection or the server is down.",
          errorCode: error.errorStatus || "NOT CONNECTED",
          url: "",
        });
      }
    );
  };

  _dismissAlert() {
    this.setState({ visible: false });
  }

  _handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  _handleSendMessage = () => {
    this.setState({
      loading: true,
    });
    const { title, message, id } = this.state;
    const user_id = id;
    APIService.sendMessage({ title, message, user_id, send_pn: true }).then(
      (success) => {
        this.setState({
          loading: false,
          title: "",
          message: "",
          messageSendingStatus: "Successful",
        });
      },
      (failure) => {
        alert(failure.errorMessage);
        this.setState({
          loading: false,
          messageSendingStatus: "Failed",
        });
      }
    );
  };

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  renderUserAgents(data) {
    return (
      <>
        <CardHeader className="border-bottom">
          <Row>
            <Col>
              <h6 className="m-0">Agents</h6>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="p-0 pb-3">
          <table className="table mb-0">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="border-0">
                  Action ID
                </th>
                <th scope="col" className="border-0">
                  User Agent
                </th>
                <th scope="col" className="border-0">
                  Created At
                </th>
                <th scope="col" className="border-0">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {!!data.user_agents
                ? data.user_agents.map((Obj, i) => (
                    <tr key={i}>
                      <td>{!!Obj.action_id ? Obj.action_id : "null"}</td>
                      <td>{Obj.user_agent}</td>
                      <td>{Obj.created_at}</td>
                      <td>{Obj.action_type}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </CardBody>
      </>
    );
  }
  renderUserDetails() {
    const { data } = this.state;
    return (
      <>
        <Container>
          <h4 color="green">{data.mobile ? data.mobile : "NA"}</h4>
          <br />
          {data.otp_expire_at ? "OTP not entered" : "Registration Successful"}
          <br />
          Created: {moment(data.created_at).format("DD-MM-YYYY hh:mm A")}
          <br />
          Updated: {moment(data.updated_at).format("DD-MM-YYYY hh:mm A")}
        </Container>
        {/* <Row>
          <Col sm={{ size: 2, order: 2, offset: 2 }}></Col>
          <Col sm={{ size: 4, order: 4, offset: 0 }}>
            {data.name ? data.name : "NA"}
          </Col>
        </Row>
        <Row>
          <Col sm={{ size: 2, order: 2, offset: 2 }}></Col>
          <Col sm={{ size: 4, order: 4, offset: 0 }}>{data.phone}</Col>
        </Row>
        <Row>
          <Col sm={{ size: 2, order: 2, offset: 2 }}></Col>
          <Col sm={{ size: 4, order: 4, offset: 0 }}>
            {data.email ? data.email : "NA"}
          </Col>
        </Row>
        <Row>
          <Col sm={{ size: 2, order: 2, offset: 2 }}></Col>
          <Col sm={{ size: 4, order: 4, offset: 0 }}>
            {data.created_at ? data.created_at : "NA"}
          </Col>
        </Row>
        <Row>
          <Col sm={{ size: 2, order: 2, offset: 2 }}></Col>
          <Col sm={{ size: 4, order: 4, offset: 0 }}>
            {data.updated_at ? data.updated_at : "NA"}
          </Col>
        </Row>
        <Row>
          <Col sm={{ size: 2, order: 2, offset: 2 }}>Otp Expiry</Col>
          <Col sm={{ size: 4, order: 4, offset: 0 }}>
            {data.otp_expire_at ? data.otp_expire_at : "NA"}
          </Col>
        </Row> */}
      </>
    );
  }

  renderUserAddresses(data) {
    return (
      <>
        <CardHeader className="border-bottom">
          <Row>
            <Col>
              <h6 className="m-0">Demo Sessions</h6>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="p-0 pb-3">
          <table className="table mb-0">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="border-0">
                  Batch
                </th>
                <th scope="col" className="border-0">
                  Date
                </th>
                <th scope="col" className="border-0">
                  Criteria
                </th>
              </tr>
            </thead>
            <tbody>
              {!!data && data.length
                ? data?.map((Obj, i) => (
                    <tr key={i}>
                      <td>{Obj.batch}</td>
                      <td>
                        {moment(Obj.date).format("DD-MM-YYYY hh:mm A")}
                      </td>
                      <td>{Obj.criteria}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </CardBody>
      </>
    );
  }

  Notifications(data) {
    return (
      <>
        <CardHeader className="border-bottom">
          <Row>
            <Col>
              <h6 className="m-0">Notifications</h6>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="p-0 pb-3">
          <table className="table mb-0">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="border-0">
                  ID
                </th>
                <th scope="col" className="border-0">
                  Mode
                </th>
                <th scope="col" className="border-0">
                  Title
                </th>
                <th scope="col" className="border-0">
                  Message
                </th>
                <th scope="col" className="border-0">
                  Sent At
                </th>
                <th scope="col" className="border-0">
                  Read
                </th>
              </tr>
            </thead>
            <tbody>
              {!!data.notifications
                ? data.notifications.map((Obj, i) => (
                    <tr key={i}>
                      <td>{Obj.id}</td>
                      <td>{Obj.type}</td>
                      <td>{Obj.title}</td>
                      <td>{Obj.message}</td>
                      <td>{Obj.created_at}</td>
                      <td>{Obj.is_unread ? "False" : "True"}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </CardBody>
      </>
    );
  }
  renderUserDevices(data) {
    return (
      <>
        <CardHeader className="border-bottom">
          <Row>
            <Col>
              <h6 className="m-0">Devices</h6>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="p-0 pb-3">
          <table className="table mb-0">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="border-0">
                  ID
                </th>
                <th scope="col" className="border-0">
                  platform
                </th>
                <th scope="col" className="border-0">
                  Updated At
                </th>
              </tr>
            </thead>
            <tbody>
              {!!data.user_devices
                ? data.user_devices.map((Obj, i) => (
                    <tr key={i}>
                      <td>{Obj.id}</td>
                      <td>{Obj.platform}</td>
                      <td>
                        {moment(Obj.created_at).format("DD-MM-YYYY hh:mm A")}
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </CardBody>
      </>
    );
  }

  renderOrders(data) {
    return (
      <>
        <CardHeader className="border-bottom">
          <Row>
            <Col>
              <h6 className="m-0">Enquiries</h6>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="p-0 pb-3">
          <table className="table mb-0">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="border-0">
                  Name
                </th>
                <th scope="col" className="border-0">
                  Looking For
                </th>
                <th scope="col" className="border-0">
                  Age
                </th>
                <th scope="col" className="border-0">
                  City
                </th>
                <th scope="col" className="border-0">
                  Coaching Before
                </th>
                <th scope="col" className="border-0">
                  Contact Number
                </th>
                <th scope="col" className="border-0">
                  created_at
                </th>
                <th scope="col" className="border-0">
                  enquiry_completed
                </th>
                <th scope="col" className="border-0" align="right" />
              </tr>
            </thead>
            <tbody>
              {!!data?.enquiries
                ? data?.enquiries.map((Obj, i) => (
                    <tr key={i}>
                      <td>{Obj.name}</td>
                      <td>{Obj.looking_for}</td>
                      <td>{Obj.age}</td>
                      <td>{Obj.city}</td>
                      <td>{Obj.coaching_before == 1 ? "Yes" : "No"}</td>
                      <td>{Obj.contact_number}</td>
                      <td>
                        {moment(Obj.created_at).format("DD-MM-YYYY hh:mm A")}
                      </td>
                      <td>{Obj.enquiry_completed ? "Yes" : "No"}</td>
                      <td align="right">
                        <ButtonGroup size="sm">
                          <Button
                            theme="secondary"
                            onClick={() => {
                              this.toggle();
                              this.setState({
                                modalData: Obj?.demo_sessions,
                              });
                            }}
                          >
                            Details
                          </Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </CardBody>
      </>
    );
  }

  render() {
    const {
      loginStatus,
      loading,
      messageSendingStatus,
      message,
      title,
      open,
      errorMessage,
      errorCode,
      data,
    } = this.state;

    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirectPath,
            state: this.state.redirectData,
          }}
        />
      );
    }
    if (errorMessage && errorCode) {
      return <APIError code={errorCode}>{errorMessage}</APIError>;
    }
    if (loading || loginStatus === undefined) {
      return <Loader />;
    } else if (loginStatus) {
      return (
        <>
          <Alert
            theme={this.state.alertStyle || "primary"}
            dismissible={this._dismissAlert}
            open={this.state.visible}
            className="mb-0"
          >
            <i className={this.state.alertIcon} /> {this.state.alertMessage}
          </Alert>
          <Container fluid className="main-content-container px-4">
            {/* Page Header with Add button */}
            <Row>
              <Col>
                <Row noGutters className="page-header py-4">
                  <Col>
                    <PageTitle
                      sm="4"
                      title="Users"
                      subtitle={appName}
                      className="text-sm-left"
                    />
                  </Col>
                </Row>
              </Col>
              <Col className="text-md-right" sm="4" xs="12">
                <Row noGutters className="page-header py-4">
                  <Col>
                    <Button
                      outline
                      theme="primary"
                      className="mb-2 mr-1"
                      onClick={() => {
                        this.setState({
                          redirect: true,
                          redirectPath: "/users",
                        });
                      }}
                    >
                      Back
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* <MainTitle title="User" labelButton="Back" /> */}

            {/* User Details and Verification Form */}
            <Row>
              <Col lg="8" className="mb-4">
                <Card small className="mb-4">
                  {this.renderOrders(data)}
                </Card>
                <Card small className="mb-4">
                  {/* {this.renderUserAddresses(data)} */}
                </Card>
              </Col>
              <Col lg="4" className="mb-4">
                <Card small className="mb-4 p-2">
                  {this.renderUserDetails()}
                </Card>
              </Col>
            </Row>
          </Container>

          <Modal open={open} toggle={this.toggle}>
          <ModalHeader>Demo Session Details</ModalHeader>
          <ModalBody>{this.renderUserAddresses(this.state.modalData)} </ModalBody>
        </Modal>
        </>
      );
    } else {
      return <Redirect to="/login" />;
    }
  }
}

export default UserDetail;
