import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Alert,
  Form,
  FormInput,
  FormSelect,
  FormTextarea,
  ListGroup,
  ListGroupItem,
} from "shards-react";
import PageTitle from "../../components/common/PageTitle";
import APIError from "../../components/common/APIError";
import Loader from "../../components/Loader/Loader";
import { Redirect } from "react-router-dom";
import { APIService } from "./../../utils/APIService";
import userLoginStatus from "./../../utils/userLoginStatus";
import JSONPretty from "react-json-pretty";
import { appName } from "../../data/constants";

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this._dismissAlert = this._dismissAlert.bind(this);
    this.state = {
      loginStatus: undefined,
      loading: false,
      listItems: false,
      internetConnected: true,
      visible: false,
      showInventoryForm: false,
      id: this.props.match.params.id,
      image: this.props.location.state ? this.props.location.state.image : "",
      name: this.props.location.state ? this.props.location.state.name : "",
      meta: this.props.location.state ? this.props.location.state.meta : {},
      is_primary: this.props.location.state
        ? this.props.location.state.is_primary
        : "",
      placement: this.props.location.state
        ? this.props.location.state.placement
        : "",
      update: this.props.location.state
        ? this.props.location.state.update
        : false,
      inventoryData: JSON.parse(localStorage.getItem("inventories")) || [],
    };

    this._handleChange = this._handleChange.bind(this);
    this._handleSubmitAdd = this._handleSubmitAdd.bind(this);
    this._handleSubmitUpdate = this._handleSubmitUpdate.bind(this);
    this._handleInventorySubmit = this._handleInventorySubmit.bind(this);
    this.inventoryRow = this.inventoryRow.bind(this);
  }

  componentDidMount() {
    let { id } = this.state;
    if (this.state.loginStatus === undefined) {
      userLoginStatus().then(
        (value) => {
          if (!!this.state.id) {
            this._fetchData(id);
          } else {
            this.setState({ loading: false, loginStatus: true });
          }
        },
        (reason) => {
          this.setState({ loginStatus: false });
        }
      );
    }
    if (!this.state.is_primary) {
    }
  }

  _fetchData(id) {
    this.setState({ loading: true });
    APIService.fetchVehicleDetail(id).then(
      (data) => {
        let {
          registration_number,
          engine_number,
          chessis_number,
          make_name,
          model_name,
          category_name,
          year_name,
          purchase_year,
        } = data;
        this.setState({
          loading: false,
          loginStatus: true,
          registration_number,
          engine_number,
          chessis_number,
          make_name,
          model_name,
          category_name,
          year_name,
          purchase_year,
        });
      },
      (error) => {
        if (!error.errorStatus || error.errorStatus === 500) {
          this.setState({
            loading: false,
            errorMessage:
              error.errorMessage ||
              "Cannot connect to server. Either there's a problem with your internet connection or the server is down.",
            errorCode: error.errorStatus || "NOT CONNECTED",
          });
        } else {
          this.setState({
            loading: false,
            visible: true,
            alertStyle: "danger",
            alertIcon: "fa fa-times-circle mx-2",
            alertMessage: error.errorMessage,
          });
        }
      }
    );
  }

  _dismissAlert() {
    this.setState({ visible: false });
  }

  _handleChange(e) {
    const { name, value } = e.target;
    if (name === "meta") {
      try {
        JSON.parse(value);
        this.setState({ [name]: value, error: false });
      } catch (error) {
        this.setState({ [name]: value, error: "Invalid JSON" });
      }
    } else {
      this.setState({ [name]: value });
    }
  }

  _handleSubmitAdd(e) {
    e.preventDefault();
    this.setState({ loading: true });
    const { roles, name, password, phone } = this.state;
    if (!roles || !name || !password || !phone) {
      alert("roles, Name, Password and Phone cannot be blank.");
      this.setState({ loading: false });
      return;
    }
    APIService.createUser({ ...this.state }).then(
      (data) => {
        this.setState({
          loading: false,
          redirect: true,
          redirectPath: "/users",
          redirectData: {
            visible: true,
            alertStyle: "success",
            alertIcon: "fa fa-check-circle mx-2",
            alertMessage: "User added successfully.",
          },
        });
      },
      (error) => {
        if (!error.errorStatus || error.errorStatus === 500) {
          this.setState({
            loading: false,
            errorMessage:
              error.errorMessage ||
              "Cannot connect to server. Either there's a problem with your internet connection or the server is down.",
            errorCode: error.errorStatus || "NOT CONNECTED",
          });
        } else {
          this.setState({
            loading: false,
            visible: true,
            alertStyle: "danger",
            alertIcon: "fa fa-times-circle mx-2",
            alertMessage: error.errorMessage,
          });
        }
      }
    );
  }

  _handleSubmitUpdate(e) {
    e.preventDefault();
    this.setState({ loading: true });
    const { id } = this.state;
    APIService.updateUser(id, this.state).then(
      (category) => {
        this.setState({
          loading: false,
          redirect: true,
          redirectPath: "/vehicles",
          redirectData: {
            visible: true,
            alertStyle: "success",
            alertIcon: "fa fa-check-circle mx-2",
            alertMessage: "User updated successfully.",
          },
        });
      },
      (error) => {
        if (!error.errorStatus || error.errorStatus === 500) {
          this.setState({
            loading: false,
            errorMessage:
              error.errorMessage ||
              "Cannot connect to server. Either there's a problem with your internet connection or the server is down.",
            errorCode: error.errorStatus || "NOT CONNECTED",
          });
        } else {
          this.setState({
            loading: false,
            visible: true,
            alertStyle: "danger",
            alertIcon: "fa fa-times-circle mx-2",
            alertMessage: error.errorMessage,
          });
        }
      }
    );
  }

  _renderForm() {
    let {
      role,
      name,
      username,
      password,
      email,
      phone,
      address1,
      address2,
      city,
      state,
      postal_code,
      update,
    } = this.state;
    // console.log(role);
    return (
      <Form
        onSubmit={update ? this._handleSubmitUpdate : this._handleSubmitAdd}
      >
        <Row form>
          <Col md="6" className="form-group">
            <label htmlFor="role">Role</label>
            <FormSelect
              id="role"
              name="roles"
              value={role}
              onChange={this._handleChange}
            >
              <option value="">Select One</option>
              <option value="super_admin">Super Admin</option>
              <option value="admin">Admin</option>
              {/* <option value="inspector">Inspector</option>
              <option value="supervisor">Supervisor</option>
              <option value="billing">Billing</option> */}
            </FormSelect>
          </Col>
          <Col md="6" className="form-group">
            <label htmlFor="name">Name</label>
            <FormInput
              id="name"
              type="text"
              placeholder="Name"
              name="name"
              value={name}
              onChange={this._handleChange}
            />
          </Col>
        </Row>
        <Row form>
          <Col md="6" className="form-group">
            <label htmlFor="email">Email</label>
            <FormInput
              id="email"
              type="email"
              placeholder="Email"
              name="email"
              value={email}
              onChange={this._handleChange}
            />
          </Col>
          <Col md="6" className="form-group">
            <label htmlFor="password">Password</label>
            <FormInput
              id="password"
              type="text"
              placeholder="Password"
              name="password"
              value={password}
              onChange={this._handleChange}
            />
          </Col>
        </Row>
        <Row form>
          <Col md="6" className="form-group">
            <label htmlFor="phone">Phone</label>
            <FormInput
              id="phone"
              type="text"
              placeholder="Phone"
              name="phone"
              value={phone}
              onChange={this._handleChange}
            />
          </Col>
        </Row>
        {/* <Row form>
          <Col md="6" className="form-group">
            <label htmlFor="address1">Address Line 1</label>
            <FormInput
              id="address1"
              type="text"
              placeholder="Address Line 1"
              name="address1"
              value={address1}
              onChange={this._handleChange}
            />
          </Col>
          <Col md="6" className="form-group">
            <label htmlFor="address2">Address Line 2</label>
            <FormInput
              id="address2"
              type="text"
              placeholder="Address Line 2"
              name="address2"
              value={address2}
              onChange={this._handleChange}
            />
          </Col>
        </Row>
        <Row form>
          <Col md="4" className="form-group">
            <label htmlFor="city">City</label>
            <FormInput
              id="city"
              type="text"
              placeholder="City"
              name="city"
              value={city}
              onChange={this._handleChange}
            />
          </Col>
          <Col md="4" className="form-group">
            <label htmlFor="state">State</label>
            <FormInput
              id="state"
              type="text"
              placeholder="State"
              name="state"
              value={state}
              onChange={this._handleChange}
            />
          </Col>
          <Col md="4" className="form-group">
            <label htmlFor="postal_code">Postal Code</label>
            <FormInput
              id="postal_code"
              type="text"
              placeholder="Postal Code"
              name="postal_code"
              value={postal_code}
              onChange={this._handleChange}
            />
          </Col>
        </Row> */}
        {update ? (
          <Button type="submit">Update</Button>
        ) : (
          <Button type="submit">Add</Button>
        )}
      </Form>
    );
  }

  inventoryRow(item) {
    return (
      <tbody>
        <tr>
          <td>{item.name}</td>
          <td className="text-md-right">
            <Button
              small
              theme="danger"
              className="mb-2 mr-1"
              onClick={() => {
                APIService.deleteCategoryInventory(item.pivot).then(
                  (data) => {},
                  (error) => {
                    alert(error.errorMessage);
                  }
                );
              }}
            >
              Remove
            </Button>
          </td>
        </tr>
      </tbody>
    );
  }

  _handleInventorySubmit(e) {
    e.preventDefault();
    let { id, inventory_id } = this.state;
    this.setState({ loading: true });
    APIService.addCategoryInventory({ category_id: id, inventory_id }).then(
      (data) => {},
      (err) => {
        alert(err.errorMessage);
        this.setState({ loading: false });
      }
    );
  }

  render() {
    const { loginStatus, loading, update, errorMessage, errorCode } =
      this.state;
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirectPath,
            state: this.state.redirectData,
          }}
        />
      );
    }
    if (errorMessage && errorCode) {
      return <APIError code={errorCode}>{errorMessage}</APIError>;
    }
    if (loading || loginStatus === undefined) {
      return <Loader />;
    } else if (loginStatus) {
      return (
        <>
          <Alert
            theme={this.state.alertStyle || "primary"}
            dismissible={this._dismissAlert}
            open={this.state.visible}
            className="mb-0"
          >
            <i className={this.state.alertIcon} /> {this.state.alertMessage}
          </Alert>
          <Container fluid className="main-content-container px-4">
            {/* Page Header with Add button */}
            <Row noGutters className="page-header py-4">
              <Col>
                <PageTitle
                  sm="4"
                  title="Users"
                  subtitle={appName}
                  className="text-sm-left"
                />
              </Col>
              <Col className="text-md-right" sm="4" xs="12" />
            </Row>

            <Row>
              <Col lg="12" className="mb-4">
                {/* Add Category Form */}
                <Card small className="mb-4">
                  <CardHeader className="border-bottom">
                    <Row>
                      <Col>
                        <h6 className="m-0">
                          {update ? "Update" : "Add"} User
                        </h6>
                      </Col>
                      <Col className="text-md-right">
                        <Button
                          outline
                          theme="primary"
                          className="mb-2 mr-1"
                          onClick={() =>
                            this.setState({
                              redirect: true,
                              redirectPath: "/users",
                            })
                          }
                        >
                          Back
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <ListGroup flush>
                    <ListGroupItem className="p-3">
                      {this._renderForm()}
                    </ListGroupItem>
                  </ListGroup>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      );
    } else {
      return <Redirect to="/login" />;
    }
  }
}

export default UserForm;
