export const displayJobItemAddRow = (cardDetails, loggedInUser) => {
  if (!cardDetails) return false;
  if (["closed"].includes(cardDetails.status) && loggedInUser.role !== "admin")
    return false;
  if (cardDetails.status == "started" && loggedInUser.role == "inspector")
    return false;
  if (
    cardDetails.status == "finished" &&
    ["inspector"].includes(loggedInUser.role)
  )
    return false;
  if (
    cardDetails.status == "billed" &&
    ["inspector", "supervisor"].includes(loggedInUser.role)
  )
    return false;
  return true;
};

export const displayMenuItem = (title, userRole) => {
  title = title.toLowerCase();
  if (userRole == "billing") {
    return ["dashboard", "jobcards"].includes(title) ? true : false;
  } else if (userRole == "inspector") {
    return ["jobcards", "service packages"].includes(title) ? true : false;
  } else if (userRole == "supervisor") {
    return ["customers", "vehicles", "jobcards", "service packages"].includes(
      title
    )
      ? true
      : false;
  }
  return true;
};

export const getJobCardThemeColor = (jobcard) => {
  return jobcard?.status == "closed"
    ? "success"
    : jobcard?.status == "open"
    ? "primary"
    : "info";
};
