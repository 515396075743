import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, LoginLayout } from "./layouts";

// Route Views
import Login from "./views/Login";
import Dashboard from "./views/Dashboard";
import UserList from "./views/Users/List";
import UserForm from "./views/Users/Form";
import UserDetail from "./views/Users/Detail";
import ErrNotFound from "./views/ErrNotFound";


export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: localStorage.getItem("user")
      ? () => <Redirect to="/users" />
      : () => <Redirect to="/login" />,
  },
  {
    path: "/login",
    layout: LoginLayout,
    component: Login,
  },
  {
    path: "/dashboard",
    layout: DefaultLayout,
    component: Dashboard,
  },
  {
    path: "/users",
    exact: true,
    layout: DefaultLayout,
    component: UserList,
  },
  {
    path: "/users/new",
    exact: true,
    layout: DefaultLayout,
    component: UserForm,
  },
  {
    path: "/users/:id/detail",
    layout: DefaultLayout,
    component: UserDetail,
  },
  
  {
    layout: LoginLayout,
    component: ErrNotFound,
  },
];
