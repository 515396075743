import config from "./../data/config";

export async function authHeader() {
  let user = await JSON.parse(localStorage.getItem("user"));
  console.log("useruser", user);
  if (user && user?.auth?.token) {
    console.log("useruser admin", user?.admin?.token);
    return {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: 'Bearer ' + user?.auth?.token,
    };
  } else {
    return {
      Accept: 'application/json',
			'Content-Type': 'application/json',
			redirect: 'follow'
    };
  }
}
