import config from './../data/config';
import base64 from 'base-64';

export function authHeaderStore(email = '', password = '') {
  // return authorization header with basic auth credentials
  if (email && password) {
    let basicAuthString = base64.encode(email + ':' + password);
    return {
      'Content-Type': config.contentType,
      api_key: config.apiKey,
      Authorization: 'Basic ' + basicAuthString
    };
  } else {
    let partner = JSON.parse(localStorage.getItem('partner'));

    if (partner && partner.auth_data) {
      return {
        'Content-Type': config.contentType,
        api_key: config.apiKey,
        Authorization: 'Basic ' + partner.auth_data
      };
    } else {
      return {};
    }
  }
}
