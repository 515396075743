export default async function() {
  let promise = new Promise((resolve, reject) => {
    let user = localStorage.getItem('user');
    if (user) {
      try {
        let userObj = JSON.parse(user);
        resolve(userObj);
      } catch (error) {
        reject(error);
      }
    } else {
      reject({ error: 'No user found in localstorage.' });
    }
  });
  let res = await promise;
  return res;
}
